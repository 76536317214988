import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const UserRegisterForSMSFEngagement = () => {
  return (
    <Layout>
      <Seo
        title="How Can a User Register for SMSF Engagement?"
        description={`In SMSF engagement, users will find clear, step-by-step instructions for gaining verification status after submitting all required details via the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`user-register-for-smsf-engagement`} />
    </Layout>
  )
}

export default UserRegisterForSMSFEngagement
